import {combineReducers} from 'redux';
import App from './app/reducer';
import Auth from "./auth/reducer";
import EditableTable from "@redux/EditableTable/reducer";
import Company from "@redux/company/reducer";
import Facility from "@redux/facility/reducer";
import Electricity from "@redux/electricity/reducer";
import Water from "@redux/water/reducer";
import Gas from "@redux/gas/reducer";
import WorkOrder from "@redux/workorder/reducer";
import Picklist from "@redux/picklist/reducer";
import SavingFilters from "@redux/SavingFilters/reducer";
import PDF from "@redux/pdf/reducer";
import Project from "@redux/project/reducer";
import Task from "@redux/task/reducer";
import SubTask from "@redux/subtask/reducer";
import Equipment from "@redux/equipment/reducer";
import EquipmentCategory from "@redux/equipmentCategory/reducer";
import Summary from "@redux/summary/reducer";
import ProjectComment from "@redux/projectcomment/reducer";
import WorkdorderComment from "@redux/workordercomment/reducer";
import FacilityBenchmark from "@redux/facilitybenchmark/reducer";

const appReducer = combineReducers({
    App,
    Auth,
    EditableTable,
    WorkOrder,
    Equipment,
    EquipmentCategory,
    Company,
    Facility,
    Electricity,
    Water,
    Gas,
    SavingFilters,
    Picklist,
    PDF,
    Project,
    Task,
    SubTask,
    Summary,
    ProjectComment,
    WorkdorderComment,
    FacilityBenchmark,
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer;
