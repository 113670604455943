import actions from './actions';

const initState = {
    SelectedPeriod: '1',
};

export default function SummaryReducer(state = initState, action) {
    switch (action.type) {
        case actions.SelectedPeriod_SUCCESS:
            return {
                ...state,
                SelectedPeriod: action.payload.data
            };
        default:
            return state;
    }
}
