import notification from "antd/es/notification";
import _ from 'lodash'

export const jsforceResponse = (result, successMessage, openSuccess, openFailed, errorMessage) => {
    if (_.get(result, 'error') || _.get(result, 'errorCode') || _.get(result, 'status') >= 300) {
        // console.log('result', result)
        if (typeof result.message === 'object') {
            for (let name in result.message) {
                notification['error']({
                    message: result.name,
                    description: name + ': ' + result.message[name]
                    , placement: 'topLeft'
                });
            }
        } else if (_.get(result, 'status') >= 300) {
            console.log('result', result)
            for (let i = 0; i < Object.keys(result.data).length; i++) {
                notification['error']({
                    message: Object.values(result.data)[i],
                    description: Object.keys(result.data)[i] + ': ' + Object.values(result.data)[i]
                    , placement: 'topLeft'
                });
            }
        } else {
            notification['error']({
                message: result.name,
                description: result.message
                , placement: 'topLeft'
            });
        }
        return [];
    } else if (_.get(result, 'message')) {
        notification['error']({
            message: 'Connection Error',
            description: result.message
            , placement: 'topLeft'
        });
        return [];

    } else {
        if (openSuccess) {
            notification['success']({
                message: successMessage,
                description: ''
                , placement: 'topLeft'
            });
        }
        return result;
    }
}



