const actions = {
    Company_REQUEST: 'LOGIN_REQUEST',
    Company_SUCCESS: 'Company_SUCCESS',

    CompanyRequest: () => ({
        type: actions.Company_REQUEST,
        payload: {},
    }),
    CompanySuccess: (data) => ({
        type: actions.Company_SUCCESS,
        payload: {data},
    }),

    SELECTED_COMPANY: 'SELECTED_COMPANY',
    SelectedCOMPANY: (data) => ({
        type: actions.SELECTED_COMPANY,
        payload: {data},
    })
};
export default actions;
