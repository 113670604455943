const actions = {
    SelectedPeriod_SUCCESS: 'SelectedPeriod_SUCCESS',

    SelectedPeriodSuccess: (data) => ({
        type: actions.SelectedPeriod_SUCCESS,
        payload: {data},
    }),

};
export default actions;
