import actions from './actions';

const initState = {
    data: [],
    selectedProject: []
};

export default function ProjectReducer(state = initState, action) {
    switch (action.type) {
        case actions.Project_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            };
        case actions.SELECTED_PROJECT:
            return {
                ...state,
                selectedProject: action.payload.data
            };
        default:
            return state;
    }
}
