const actions = {
    ProjectComment_REQUEST: 'ProjectComment_REQUEST',
    ProjectComment_SUCCESS: 'ProjectComment_SUCCESS',

    ProjectCommentRequest: () => ({
        type: actions.ProjectComment_REQUEST,
        payload: {},
    }),
    ProjectCommentSuccess: (data) => ({
        type: actions.ProjectComment_SUCCESS,
        payload: {data},
    }),
};
export default actions;
