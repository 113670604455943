const actions = {
    FacilityBenchmark_REQUEST: 'FacilityBenchmark_REQUEST',
    FacilityBenchmark_SUCCESS: 'FacilityBenchmark_SUCCESS',

    FacilityBenchmarkRequest: () => ({
        type: actions.FacilityBenchmark_REQUEST,
        payload: {},
    }),
    FacilityBenchmarkSuccess: (data) => ({
        type: actions.FacilityBenchmark_SUCCESS,
        payload: {data},
    })
};
export default actions;
