import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';

import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};
const initialState = {};
// const bindMiddleware = middleware => {
//     if (process.env.NODE_ENV !== 'production') {
//         const {composeWithDevTools} = require('redux-devtools-extension');
//         return composeWithDevTools(applyMiddleware(...middleware));
//     }
//
//     return applyMiddleware(...middleware);
// };
const bindMiddleware = middleware => {
    const {composeWithDevTools} = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
};
const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer, initialState, bindMiddleware(middlewares));
sagaMiddleware.run(rootSaga);
export {store};
export const persistor = persistStore(store);
