const actions = {
    WorkorderComment_REQUEST: 'WorkorderComment_REQUEST',
    WorkorderComment_SUCCESS: 'WorkorderComment_SUCCESS',

    WorkorderCommentRequest: () => ({
        type: actions.WorkorderComment_REQUEST,
        payload: {},
    }),
    WorkorderCommentSuccess: (data) => ({
        type: actions.WorkorderComment_SUCCESS,
        payload: {data},
    }),
};
export default actions;
