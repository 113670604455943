import actions from './actions';

const initState = {
    data: [],
    selectedCompany: []
};

export default function CompanyReducer(state = initState, action) {
    switch (action.type) {
        case actions.Company_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            };
        case actions.SELECTED_COMPANY:
            return {
                ...state,
                selectedCompany: action.payload.data
            };
        default:
            return state;
    }
}
