const actions = {
    Picklist_REQUEST: 'LOGIN_REQUEST',
    Picklist_SUCCESS: 'Picklist_SUCCESS',

    PicklistRequest: () => ({
        type: actions.Picklist_REQUEST,
        payload: {},
    }),
    PicklistSuccess: (data) => ({
        type: actions.Picklist_SUCCESS,
        payload: {data},
    }),


};
export default actions;
