const actions = {
    Electricity_REQUEST: 'Electricity_REQUEST',
    Electricity_SUCCESS: 'Electricity_SUCCESS',

    ElectricityRequest: () => ({
        type: actions.Electricity_REQUEST,
        payload: {},
    }),
    ElectricitySuccess: (data) => ({
        type: actions.Electricity_SUCCESS,
        payload: {data},
    })
};
export default actions;
