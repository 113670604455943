import actions from './actions';

const initState = {
    data: [],
    selectedEquipment: []
};

export default function EquipmentReducer(state = initState, action) {
    switch (action.type) {
        case actions.Equipment_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            };
        case actions.SELECTED_EQUIPMENT:
            return {
                ...state,
                selectedEquipment: action.payload.data
            };
        default:
            return state;
    }
}
