import {all, call, put, takeEvery} from "redux-saga/effects";
import actions from './actions';
import {jsforceResponse} from "@/config/httpService";
import {api} from "@redux/api";

export function* getWorkOrderData() {
    let result = yield call(api.getWorkorder);
    result = jsforceResponse(result, '', false, true)
    console.log('result', result.data.results)
    if (result.status === 200) {
        yield put(actions.WorkorderSuccess(result.data.results));
    }

}


export function* watchGetWorkOrder() {
    yield takeEvery(actions.Workorder_REQUEST, getWorkOrderData);
}

export default function* WorkOrderSage() {
    yield all(
        [
            watchGetWorkOrder()
        ]
    )
}
