const actions = {
    Project_REQUEST: 'LOGIN_REQUEST',
    Project_SUCCESS: 'Project_SUCCESS',

    ProjectRequest: () => ({
        type: actions.Project_REQUEST,
        payload: {},
    }),
    ProjectSuccess: (data) => ({
        type: actions.Project_SUCCESS,
        payload: {data},
    }),

    SELECTED_PROJECT: 'SELECTED_PROJECT',
    SelectedPROJECT: (data) => ({
        type: actions.SELECTED_PROJECT,
        payload: {data},
    })
};
export default actions;
