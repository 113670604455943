const actions = {
    EquipmentCategory_REQUEST: 'LOGIN_REQUEST',
    EquipmentCategory_SUCCESS: 'EquipmentCategory_SUCCESS',

    EquipmentCategoryRequest: () => ({
        type: actions.EquipmentCategory_REQUEST,
        payload: {},
    }),
    EquipmentCategorySuccess: (data) => ({
        type: actions.EquipmentCategory_SUCCESS,
        payload: {data},
    }),

    SELECTED_EquipmentCategory: 'SELECTED_EquipmentCategory',
    SelectedEquipmentCategory: (data) => ({
        type: actions.SELECTED_EquipmentCategory,
        payload: {data},
    })
};
export default actions;
