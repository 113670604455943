import axios from "axios";
import {store} from "@redux/store";
import authActions from "@redux/auth/actions";
import moment from "moment";
import notification from "antd/es/notification";
import _ from "lodash";
import React from 'react';

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
    (config) => {
        const access = localStorage.getItem("access");
        const facility_id = store.getState().Facility.selected.Id;
        if (access) {
            config.headers["Authorization"] = 'Bearer ' + access;
            config.params = {...config.params, facility: facility_id}
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

//response interceptor to refresh token on receiving token expired
axios.interceptors.response.use(
    (response) => {
        if (response.config.method !== 'get' && !response.config.url.includes('log') && !response.config.url.includes('token/refresh')) {

            api.createLog({
                status: response.status,
                type: response.config.method,
                message: typeof response.config.data === 'string' ? response.config.data : '{}',
                params: JSON.stringify(response.config.params),
                url: response.config.url,
                company: store.getState().Facility.selected.record.company_id
            })
        }
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        let refresh = localStorage.getItem("refresh");
        let access = localStorage.getItem("access");
        if (error.response.status === 401 &&
            originalRequest.url === process.env.REACT_APP_BACKEND + `/auth/token/refresh`
        ) {
            // console.log('error', error.response.data)
            // notification['error']({
            //     message: error.response.data.code,
            //     description:  error.response.data.detail
            // });
            if (error.response.data.code === 'token_not_valid') {
                store.dispatch(authActions.logout(refresh, access));
            }
            return Promise.reject(error);
        } else if (
            refresh &&
            error.response.status === 401 &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;
            return axios
                .post(process.env.REACT_APP_BACKEND + `/auth/token/refresh`, {refresh: refresh})
                .then((res) => {
                    console.log('res', res)
                    if (res.status === 200) {
                        localStorage.setItem("access", res.data.access);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access;
                        console.log("Access token refreshed!");
                        return axios(originalRequest);
                    }
                })
        } else if (error.response.status >= 400) {
            console.log('error.response.data', error.response.data)
            let error_data = error.response.data;
            let error_data_response = []


            if (typeof error_data === 'object') {
                Object.keys(error_data).forEach(key => {
                    error_data_response.push(
                        <span>{key + ': ' + JSON.stringify(error_data[key])}<br></br></span>
                    )
                })
            } else {
                error_data_response = error_data
            }

            if (_.get(error.response, 'data.detail') === 'User has no permission.') {
                window.location.href = '/dashboard';
                window.alert(_.get(error.response, 'data.detail'));
            }

            notification['error']({
                message: error.response.statusText,
                description: error_data_response,
            });

            api.createLog({
                status: error.response.status,
                type: error.config.method,
                message: typeof error.config.data === 'string' ? error.config.data : '{}',
                params: JSON.stringify(error.config.params),
                url: error.config.url,
                company: store.getState().Facility.selected.record.company_id
            })

        }

        return Promise.reject(error);
    }
);

export const RquestSignUp = async (e) => {

    e['date_of_birth'] = moment(e['date_of_birth']).format('YYYY-MM-DD')
    if (typeof e['image'] !== 'object') {
        delete e['image']
    }
    var myHeaders = new Headers();
    myHeaders.append("content-type", "multipart/form-data");

    var data = new FormData();
    for (const name in e) {
        data.append(name, e[name]);
    }
    var result = [];
    try {
        var config = {
            method: 'post',
            url: process.env.REACT_APP_BACKEND + '/auth/signup/',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: data
        };

        return await axios(config)
            .then(function (response) {
                console.log('response', response)
                return response;
            })
            .catch(function (error) {
                let err = {
                    error: 400,
                    message: error.response.data
                }
                return err;
            });

    } catch (e) {
        console.log('exception', e)
        result = e;
    }
    return result;
}

export const update_profile = async (id, e) => {
    if (e['date_of_birth']) {
        e['date_of_birth'] = moment(e['date_of_birth']).format('YYYY-MM-DD')
    }

    if (typeof e['image'] !== 'object') {
        delete e['image']
    }

    var myHeaders = new Headers();
    myHeaders.append("content-type", "multipart/form-data");

    var data = new FormData();
    for (const name in e) {
        data.append(name, e[name]);
    }
    var result = [];
    try {
        var config = {
            method: 'put',
            url: process.env.REACT_APP_BACKEND + `/api/v2/update_profile/` + id + '/',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: data
        };

        return await axios(config)
            .then(function (response) {
                console.log('response', response)
                return response;
            })
            .catch(function (error) {
                let err = {
                    error: 400,
                    message: error.response.data
                }
                return err;
            });

    } catch (e) {
        console.log('exception', e)
        result = e;
    }
    return result;
}

export const api = {
    getLog: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/log/?' + query)
    },
    createLog: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/log/', body)
    },

    getFiles: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/file/?' + query)
    },

    bulkDeleteFiles: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/file/bulk_delete/', body)
    },
    deleteFile: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/file/' + id + '/')
    },

    uploadFile: (body) => {

        if (typeof body['image'] !== 'object') {
            delete body['image']
            return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/file/', body)
        } else {
            var myHeaders = new Headers();
            myHeaders.append("content-type", "multipart/form-data");

            var data = new FormData();
            for (const name in body) {
                data.append(name, body[name]);
            }
            var config = {
                method: 'post',
                url: process.env.REACT_APP_BACKEND + '/api/v2/file/',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data: data
            };

            return axios(config)
                .then(function (response) {
                    console.log('response', response)
                    return response;
                })
                .catch(function (error) {
                    let err = {
                        error: 400,
                        message: error.response.data
                    }
                    return err;
                });
        }

    },

    generate_url: (key_name, is_preview) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/generate_public_url?key_name=' + key_name + '&is_preview=' + is_preview)
    },

    hello: () => {
        return axios.get(process.env.REACT_APP_BACKEND + `/hello`);
    },
    daterange: () => {
        return axios.get(process.env.REACT_APP_BACKEND + `/daterange`);
    },
    linearreg: (x, y, post_x, post_y) => {
        let body = {x: x, y: y, post_x: post_x, post_y: post_y};
        return axios.post(process.env.REACT_APP_BACKEND + `/linearreg`, body);
    },
    login: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + `/staff/auth/token/`, body);
    },

    refreshToken: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + `/auth/token/refresh`, body);
    },
    logout: (body, accessToken) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }
        return axios.post(process.env.REACT_APP_BACKEND + `/logout`, body, config);
    },
    getProtected: () => {
        return axios.get(process.env.REACT_APP_BACKEND + `/protected_resource`);
    },
    changePassword: (id, body) => {
        return axios.post(process.env.REACT_APP_BACKEND + `/api/v2/change_password/` + id + '/', body);
    },
    alltasks: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + `/alltasks?` + query);
    },
    getFacilityConsumption: (company_id) => {
        return axios.get(process.env.REACT_APP_BACKEND + `/getfacilityconsumption?company_id=` + company_id);
    },
    getElecGas: () => {
        return axios.get(process.env.REACT_APP_BACKEND + `/elecgas`);
    },
    /**
     user facility
     **/
    getUserFacilities: (user_id, accessToken) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }
        return axios.get(process.env.REACT_APP_BACKEND + `/api/v2/userfacilities/?auth_CustomUser_id=` + user_id, config)
    },

    /**
     company crud
     **/
    // getCompany: (accessToken) => {
    //     let config = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + accessToken
    //         }
    //     }
    //     return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/companies/?', config)
    // },

    /**
     user crud
     **/
    getCustomUsers: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/customusers/?page=1&page_size=24')
    },
    createCustomUser: (body) => {
        if (typeof body['image'] !== 'object') {
            delete body['image']
            return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/customusers/', body)
        } else {
            var myHeaders = new Headers();
            myHeaders.append("content-type", "multipart/form-data");

            var data = new FormData();
            for (const name in body) {
                data.append(name, body[name]);
            }
            var config = {
                method: 'post',
                url: process.env.REACT_APP_BACKEND + '/api/v2/customusers/',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data: data
            };

            return axios(config)
                .then(function (response) {
                    console.log('response', response)
                    return response;
                })
                .catch(function (error) {
                    let err = {
                        error: 400,
                        message: error.response.data
                    }
                    return err;
                });
        }
    },
    bulkCustomUserInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/customusers/bulk_insert/', body)
    },
    bulkDeleteCustomUser: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/customusers/bulk_delete/', body)
    },
    deleteCustomUser: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/customusers/' + id + '/')
    },
    editCustomUser: (key, row) => {
        if (typeof row['image'] !== 'object') {
            delete row['image']
            return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/customusers/' + key + '/', row)
        } else {
            var myHeaders = new Headers();
            myHeaders.append("content-type", "multipart/form-data");

            var data = new FormData();
            for (const name in row) {
                data.append(name, row[name]);
            }
            var config = {
                method: 'put',
                url: process.env.REACT_APP_BACKEND + '/api/v2/customusers/' + key + '/',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data: data
            };

            return axios(config)
                .then(function (response) {
                    console.log('response', response)
                    return response;
                })
                .catch(function (error) {
                    let err = {
                        error: 400,
                        message: error.response.data
                    }
                    return err;
                });
        }
    },
    filterCustomUsers: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/customusers/?' + query)
    },
    searchGlobalCustomUsers: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/customusers/?search=' + queryStr)
    },

    // userpermissions
    getUserpermissions: (selecteduser) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/userpermissions/?selecteduser=' + selecteduser)
    },
    editUserpermissions: (key, row) => {
        // console.log(key, row)
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/userpermissions/', row)
    },
    /**
     EquipmentCategory crud
     **/
    getEquipmentCategory: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/equipmentcategories/?page=1&page_size=24')
    },
    createEquipmentCategory: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/equipmentcategories/', body)
    },
    bulkEquipmentCategoryInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/equipmentcategories/bulk_insert/', body)
    },
    bulkDeleteEquipmentCategory: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/equipmentcategories/bulk_delete/', body)
    },
    deleteEquipmentCategory: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/equipmentcategories/' + id + '/')
    },
    editEquipmentCategory: (key, row) => {
        // console.log(key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/equipmentcategories/' + key + '/', row)
    },
    filterEquipmentCategory: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/equipmentcategories/?' + query)
    },
    searchGlobalEquipmentCategory: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/equipmentcategories/?search=' + queryStr)
    },

    /**
     Company crud
     **/
    getCompany: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/companies/?page=1&page_size=24')
    },
    createCompany: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/companies/', body)
    },
    bulkCompanyInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/companies/bulk_insert/', body)
    },
    bulkDeleteCompany: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/companies/bulk_delete/', body)
    },
    deleteCompany: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/companies/' + id + '/')
    },
    editCompany: (key, row) => {
        // console.log(key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/companies/' + key + '/', row)
    },
    filterCompany: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/companies/?' + query)
    },
    searchGlobalCompany: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/companies/?search=' + queryStr)
    },

    /**
     Equipment crud
     **/
    getEquipment: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/equipments/?page=1&page_size=24')
    },
    createEquipment: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/equipments/', body)
    },
    bulkEquipmentInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/equipments/bulk_insert/', body)
    },
    bulkDeleteEquipment: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/equipments/bulk_delete/', body)
    },
    deleteEquipment: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/equipments/' + id + '/')
    },
    editEquipment: (key, row) => {
        console.log(key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/equipments/' + key + '/', row)
    },
    filterEquipment: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/equipments/?' + query)
    },
    searchGlobalEquipment: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/equipments/?search=' + queryStr)
    },


    /**
     electricity crud
     **/
    getElectricity: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/electricities/?page=1&page_size=24')
    },
    createElectricity: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/electricities/', body)
    },
    bulkElectricityInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/electricities/bulk_insert/', body)
    },
    bulkDeleteElectricity: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/electricities/bulk_delete/', body)
    },
    deleteElectricity: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/electricities/' + id + '/')
    },
    editElectricity: (key, row) => {
        console.log(key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/electricities/' + key + '/', row)
    },
    filterElectricity: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/electricities/?' + query)
    },
    searchGlobalElectricity: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/electricities/?search=' + queryStr)
    },

    /**
     facility crud
     **/
    getFacility: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/facilities/?page=1&page_size=24')
    },
    createFacility: (body) => {
        if (typeof body['image'] !== 'object') {
            delete body['image']
            return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/facilities/', body)
        } else {
            var myHeaders = new Headers();
            myHeaders.append("content-type", "multipart/form-data");

            var data = new FormData();
            for (const name in body) {
                data.append(name, body[name]);
            }
            var config = {
                method: 'post',
                url: process.env.REACT_APP_BACKEND + '/api/v2/facilities/',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data: data
            };

            return axios(config)
                .then(function (response) {
                    console.log('response', response)
                    return response;
                })
                .catch(function (error) {
                    let err = {
                        error: 400,
                        message: error.response.data
                    }
                    return err;
                });
        }
    },
    bulkFacilityInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/facilities/bulk_insert/', body)
    },
    bulkDeleteFacility: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/facilities/bulk_delete/', body)
    },
    deleteFacility: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/facilities/' + id + '/')
    },
    editFacility: (key, row) => {
        if (typeof row['image'] !== 'object' || !row['image']) {
            delete row['image']
            return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/facilities/' + key + '/', row)
        } else {
            var myHeaders = new Headers();
            myHeaders.append("content-type", "multipart/form-data");

            var data = new FormData();
            for (const name in row) {
                data.append(name, row[name]);
            }
            var config = {
                method: 'put',
                url: process.env.REACT_APP_BACKEND + '/api/v2/facilities/' + key + '/',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data: data
            };

            return axios(config)
                .then(function (response) {
                    console.log('response', response)
                    return response;
                })
                .catch(function (error) {
                    let err = {
                        error: 400,
                        message: error.response.data
                    }
                    return err;
                });
        }


    },
    filterFacility: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/facilities/?' + query)
    },
    searchGlobalFacility: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/facilities/?search=' + queryStr)
    },

    /**
     gas crud
     **/
    getGas: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/gases/?page=1&page_size=24')
    },
    createGas: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/gases/', body)
    },
    bulkGasInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/gases/bulk_insert/', body)
    },
    bulkDeleteGas: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/gases/bulk_delete/', body)
    },
    deleteGas: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/gases/' + id + '/')
    },
    editGas: (key, row) => {
        // console.log(key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/gases/' + key + '/', row)
    },
    filterGas: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/gases/?' + query)
    },
    searchGlobalGas: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/gases/?search=' + queryStr)
    },

    /**
     water crud
     **/
    getWater: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/waters/?page=1&page_size=24')
    },
    createWater: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/waters/', body)
    },
    bulkWaterInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/waters/bulk_insert/', body)
    },
    bulkDeleteWater: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/waters/bulk_delete/', body)
    },
    deleteWater: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/waters/' + id + '/')
    },
    editWater: (key, row) => {
        // console.log(key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/waters/' + key + '/', row)
    },
    filterWater: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/waters/?' + query)
    },
    searchGlobalWater: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/waters/?search=' + queryStr)
    },


    /**
     workorder crud
     **/
    getWorkorder: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/workorders/?page=1&page_size=24')
    },
    createWorkorder: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/workorders/', body)
    },
    bulkWorkorderInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/workorders/bulk_insert/', body)
    },
    bulkDeleteWorkorder: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/workorders/bulk_delete/', body)
    },
    deleteWorkorder: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/workorders/' + id + '/')
    },
    editWorkorder: (key, row) => {
        // console.log(key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/workorders/' + key + '/', row)
    },
    filterWorkorder: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/workorders/?' + query)
    },
    searchGlobalWorkorder: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/workorders/?search=' + queryStr)
    },

    /**
     WorkorderComment crud
     **/
    getWorkorderComment: (workorderid) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/workordercomments/?workorderid=' + workorderid + '&page=1&page_size=24')
    },
    createWorkorderComment: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/workordercomments/', body)
    },

    deleteWorkorderComment: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/workordercomments/' + id + '/')
    },
    editWorkorderComment: (row) => {
        // console.log(key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/workordercomments/', row)
    },
    filterWorkorderComment: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/workordercomments/?' + query)
    },


    /**
     ProjectComment crud
     **/
    getProjectComment: (projectid) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/projectcomments/?projectid=' + projectid + '&page=1&page_size=24')
    },
    createProjectComment: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/projectcomments/', body)
    },

    deleteProjectComment: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/projectcomments/' + id + '/')
    },
    editProjectComment: (row) => {
        // console.log(key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/projectcomments/', row)
    },
    filterProjectComment: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/projectcomments/?' + query)
    },

    /**
     gas crud
     **/
    getPicklist: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/picklists/?page=1&page_size=24')
    },
    createPicklist: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/picklists/', body)
    },
    bulkPicklistInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/picklists/bulk_insert/', body)
    },
    bulkDeletePicklist: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/picklists/bulk_delete/', body)
    },
    deletePicklist: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/picklists/' + id + '/')
    },
    editPicklist: (key, row) => {
        // console.log('editPicklist', key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/picklists/' + key + '/', row)
    },
    filterPicklist: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/picklists/?' + query)
    },
    searchGlobalPicklist: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/picklists/?search=' + queryStr)
    },

    /**
     project crud
     **/
    getProjects: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/projects/?page=1&page_size=24')
    },
    createProject: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/projects/', body)
    },
    bulkProjectInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/projects/bulk_insert/', body)
    },
    bulkDeleteProject: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/projects/bulk_delete/', body)
    },
    deleteProject: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/projects/' + id + '/')
    },
    editProject: (key, row) => {
        // console.log('editProject', key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/projects/' + key + '/', row)
    },
    filterProject: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/projects/?' + query)
    },
    searchGlobalProjects: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/projects/?search=' + queryStr)
    },


    /**
     task crud
     **/
    getTasks: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/tasks/?page=1&page_size=24')
    },
    createTask: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/tasks/', body)
    },
    bulkTaskInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/tasks/bulk_insert/', body)
    },
    bulkDeleteTask: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/tasks/bulk_delete/', body)
    },
    deleteTask: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/tasks/' + id + '/')
    },
    editTask: (key, row) => {
        // console.log('editTask', key, row)
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/tasks/' + key + '/', row)
    },
    filterTask: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/tasks/?' + query)
    },
    searchGlobalTasks: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/tasks/?search=' + queryStr)
    },


    /**
     subtask crud
     **/
    getSubTasks: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/subtasks/?page=1&page_size=24')
    },
    createSubTask: (body) => {
        return axios.post(process.env.REACT_APP_BACKEND + '/api/v2/subtasks/', body)
    },
    bulkSubTaskInsert: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/subtasks/bulk_insert/', body)
    },
    bulkDeleteSubTask: (body) => {
        return axios.patch(process.env.REACT_APP_BACKEND + '/api/v2/subtasks/bulk_delete/', body)
    },
    deleteSubTask: (id) => {
        return axios.delete(process.env.REACT_APP_BACKEND + '/api/v2/subtasks/' + id + '/')
    },
    editSubTask: (key, row) => {
        return axios.put(process.env.REACT_APP_BACKEND + '/api/v2/subtasks/' + key + '/', row)
    },
    filterSubTask: (query) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/subtasks/?' + query)
    },
    searchGlobalSubTasks: (queryStr) => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/subtasks/?search=' + queryStr)
    },


//    benchmark
    getFacilityBenchmark: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/allfacilitybenchmark/')
    },

    getNationalBenchmark: () => {
        return axios.get(process.env.REACT_APP_BACKEND + '/api/v2/nationalbenchmark/')
    },

};


export const uploadFile = async (data, auth, recordId, domain, userid) => {

    var result = [];
    // let ext = '74bc8700-1307-4c25-b8c5-472236672c9b'
    console.log(data)
    let fileList = _.get(data, 'file', []);
    const formData = new FormData();

    fileList.forEach((file) => {   // fileList 是要上传的文件数组
        formData.append('file', file.originFileObj);
    });

    console.log('formData', fileList, formData)
    try {
        var config = {
            method: 'post',
            url: domain + '/api/v1/services/obj/file-management/share/' + recordId,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + auth
            },
            data: formData,
            params: {
                linkTo: recordId,
                shareToClient: _.get(data, 'ShareToClient'),
                category: 'Other',
                asOfDate: moment().format('YYYY-MM-DD'),
                createdBy: userid
            }
        };

        return await axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.log('error', error.response.data)
                return error;
            });

    } catch (e) {
        result = e;
    }
    return result;
}

export const getFiles = async (auth, recordId, domain, fileName, pageNumber, pageSize, paged) => {
    var result = [];
    try {
        var config = {
            method: 'get',
            url: domain + '/api/v1/services/obj/file-management/linkedTo/' + recordId,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + auth
            },
            params: {
                fileName: fileName,
                pageSize: pageSize,
                pageNumber: pageNumber,
                paged: paged
            }
        };

        return await axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.log('error', error.response.data)
                return error;
            });

    } catch (e) {
        result = e;
    }
    return result;
}


export const getVersions = async (auth, domain, externalId, pageNumber, pageSize, paged) => {
    var result = [];
    try {
        var config = {
            method: 'get',
            url: domain + '/api/v1/services/obj/file-management/files/' + externalId + '/versions',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + auth
            },
            params: {
                externalId: externalId,
                pageSize: pageSize,
                pageNumber: pageNumber,
                paged: paged
            }
        };

        return await axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.log('error', error.response.data)
                return error;
            });

    } catch (e) {
        result = e;
    }
    return result;
}




