const actions = {
    Gas_REQUEST: 'Gas_REQUEST',
    Gas_SUCCESS: 'Gas_SUCCESS',

    GasRequest: () => ({
        type: actions.Gas_REQUEST,
        payload: {},
    }),
    GasSuccess: (data) => ({
        type: actions.Gas_SUCCESS,
        payload: {data},
    })
};
export default actions;
