const actions = {
    Equipment_REQUEST: 'LOGIN_REQUEST',
    Equipment_SUCCESS: 'Equipment_SUCCESS',

    EquipmentRequest: () => ({
        type: actions.Equipment_REQUEST,
        payload: {},
    }),
    EquipmentSuccess: (data) => ({
        type: actions.Equipment_SUCCESS,
        payload: {data},
    }),

    SELECTED_EQUIPMENT: 'SELECTED_EQUIPMENT',
    SelectedEquipment: (data) => ({
        type: actions.SELECTED_EQUIPMENT,
        payload: {data},
    })
};
export default actions;
