import actions from './actions';

const initState = {
    access: null, user: null, refresh: null, image: null, permissions: [], permission: [], Email: [],
    company: [], role: [], first_name: null, last_name: null, middle_name: null, IPAddress: []
};
export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                access: action.access,
                refresh: action.refresh,
                user: action.user,
                userId: action.userId,
                image: action.image,
                company: action.company,
                user_facilities: action.user_facilities,
                is_companyadmin: action.is_companyadmin,
                permission: action.permission,
                role: action.role,
                first_name: action.first_name,
                last_name: action.last_name,
                middle_name: action.middle_name,
                email: action.email
            };
        case actions.IPADDRESS:
            return {
                ...state,
                IPAddress: action.payload.data
            }
        case actions.PERMISSIONS:
            return {
                ...state,
                permissions: action.payload.data
            }
        case actions.LOGOUT:
            return initState;
        default:
            return state;
    }
}
