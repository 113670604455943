const actions = {
    Workorder_REQUEST: 'Workorder_REQUEST',
    Workorder_SUCCESS: 'Workorder_SUCCESS',

    WorkorderRequest: () => ({
        type: actions.Workorder_REQUEST,
        payload: {},
    }),
    WorkorderSuccess: (data) => ({
        type: actions.Workorder_SUCCESS,
        payload: {data},
    }),
};
export default actions;
