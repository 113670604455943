import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
// import LogRocket from 'logrocket';
import './index.css';
import './assets/styles/tailwind.min.css'

// if (process.env.NODE_ENV === 'production') {
//     console.log = function () {
//     };
//     LogRocket.init('lvhycl/stageproject');
// }
ReactDOM.render(<App/>, document.getElementById('root'));

