const actions = {
    Facility_REQUEST: 'LOGIN_REQUEST',
    Facility_SUCCESS: 'Facility_SUCCESS',
    Selected_Facility: 'Selected_Facility',
    Selected_Address: 'Selected_Address',
    Selected_GeoLocation: 'Selected_GeoLocation',
    Selected_Admin_Facility: 'Selected_Admin_Facility',

    FacilityRequest: () => ({
        type: actions.Facility_REQUEST,
        payload: {},
    }),
    FacilitySuccess: (data) => ({
        type: actions.Facility_SUCCESS,
        payload: {data},
    }),
    SelectedFacility: (data) => ({
        type: actions.Selected_Facility,
        payload: {data},
    }),
    SelectedGeoLocation: (data) => ({
        type: actions.Selected_GeoLocation,
        payload: {data},
    }),
    SelectedAddress: (data) => ({
        type: actions.Selected_Address,
        payload: {data},
    }),
    SelectedAdminFacility: (data) => ({
        type: actions.Selected_Admin_Facility,
        payload: {data},
    })
};
export default actions;
