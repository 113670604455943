const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT: 'LOGOUT',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    PERMISSIONS: 'PERMISSIONS',
    IPADDRESS: 'IPADDRESS',

    checkAuthorization: () => ({type: actions.CHECK_AUTHORIZATION}),
    login: (tokens) => ({
        type: actions.LOGIN_REQUEST,
        payload: {tokens},
    }),
    logout: (refresh, authToken) => ({
        type: actions.LOGOUT,
        payload: {refresh, authToken},
    }),
    permissions: (data) => ({
        type: actions.PERMISSIONS,
        payload: {data},
    }),
    ipaddress: (data) => ({
        type: actions.IPADDRESS,
        payload: {data},
    })
};
export default actions;
