import actions from './actions';

const initState = {};

export default function PDFReducer(state = initState, action) {
    switch (action.type) {

        case actions.SET_PDFDATA:
            return {
                ...state,
                ['pdf' + action.payload.index]: action.payload
            };


        default:
            return state;
    }
}
