const actions = {
    SubTask_REQUEST: 'LOGIN_REQUEST',
    SubTask_SUCCESS: 'SubTask_SUCCESS',

    SubTaskRequest: () => ({
        type: actions.SubTask_REQUEST,
        payload: {},
    }),
    SubTaskSuccess: (data) => ({
        type: actions.SubTask_SUCCESS,
        payload: {data},
    }),

};
export default actions;
