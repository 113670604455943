const actions = {
    Water_REQUEST: 'Water_REQUEST',
    Water_SUCCESS: 'Water_SUCCESS',

    WaterRequest: () => ({
        type: actions.Water_REQUEST,
        payload: {},
    }),
    WaterSuccess: (data) => ({
        type: actions.Water_SUCCESS,
        payload: {data},
    })
};
export default actions;
