const actions = {
    Task_REQUEST: 'LOGIN_REQUEST',
    Task_SUCCESS: 'Task_SUCCESS',

    TaskRequest: () => ({
        type: actions.Task_REQUEST,
        payload: {},
    }),
    TaskSuccess: (data) => ({
        type: actions.Task_SUCCESS,
        payload: {data},
    }),

    CENTER_DATE: 'CENTER_DATE',
    SetCenterDate: (date, duration, max_task_date, min_task_date) => ({
        type: actions.CENTER_DATE,
        payload: {date, duration, max_task_date, min_task_date},
    }),

    SELECTED_TASK: 'SELECTED_TASK',
    SelectedTask: (task) => ({
        type: actions.SELECTED_TASK,
        payload: {task},
    }),

    IS_OPEN: 'IS_OPEN',
    SetIsOpen: (isOpen) => ({
        type: actions.IS_OPEN,
        payload: {isOpen},
    })
};
export default actions;
