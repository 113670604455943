import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch,} from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import Loader from './components/utility/loader';
import {useDispatch, useSelector} from "react-redux";
import authAction from "@redux/auth/actions";
import facilityActions from "@redux/facility/actions";

const {login} = authAction;
const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const PUBLIC_ROUTE = {
    LANDING: '/',
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    PAGE_404: '/404',
    PAGE_500: '/500',
};

// This route will only be visible to users with a valid accessToken.
export const PRIVATE_ROUTE = {
    DASHBOARD: '/'
}

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: lazy(() => import('./containers/LoginPages/SignIn/SignIn')),
    },
    {
        path: PUBLIC_ROUTE.SIGN_UP,
        exact: true,
        component: lazy(() => import('./containers/LoginPages/SignUp/SignUp')),
    }
];

function PrivateRoute({children, ...rest}) {
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    let isLoggedIn = useSelector((state) => state.Auth.access);
    let auth = urlParams.get('auth');
    let facility = urlParams.get('facility');
    if (auth) {
        auth = JSON.parse(auth)
        dispatch(login(auth));
        facility = JSON.parse(facility)
        dispatch(facilityActions.SelectedFacility(facility))
        isLoggedIn = true
    }

    return (
        <Route
            {...rest}
            render={({location}) =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: {from: location},
                        }}
                    />
                )
            }
        />
    );
}

export default function Routes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader/>}>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, index) => (
                            <Route key={index} path={route.path} exact={route.exact}>
                                <route.component/>
                            </Route>
                        ))}
                        <PrivateRoute path="/">
                            <Dashboard/>
                        </PrivateRoute>
                        <Route render={() => <Redirect to={{pathname: "/"}}/>}/>
                    </Switch>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
}
