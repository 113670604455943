import {all, call, put, takeEvery} from "redux-saga/effects";
import actions from './actions';
import {jsforceResponse} from "@/config/httpService";
import {api} from "@redux/api";

export function* getElectricityData() {
    let result = yield call(api.getElectricity);
    result = jsforceResponse(result, '', false, true)
    console.log('result', result.data.results)
    if (result.status === 200) {
        yield put(actions.ElectricitySuccess(result.data.results));
    }

}


export function* watchGetElectricity() {
    yield takeEvery(actions.Electricity_REQUEST, getElectricityData);
}

export default function* ElectricitySage() {
    yield all(
        [
            watchGetElectricity()
        ]
    )
}
