import {all, fork, put, takeEvery} from 'redux-saga/effects';
import _ from "lodash";
import actions from './actions';
import {createBrowserHistory} from 'history';

const history = createBrowserHistory();

export function* loginRequest() {
    yield takeEvery(actions.LOGIN_REQUEST, function* (payload) {
        // console.log('payload', payload.payload)
        const access = _.get(payload.payload, 'tokens.access');
        const refresh = _.get(payload.payload, 'tokens.refresh');
        const user = _.get(payload.payload, 'tokens.user');
        const image = _.get(payload.payload, 'tokens.user_image');
        const company = _.get(payload.payload, 'tokens.company');
        const user_facilities = _.get(payload.payload, 'tokens.user_facilities');
        const is_companyadmin = _.get(payload.payload, 'tokens.is_companyadmin');
        const userId = _.get(payload.payload, 'tokens.user_id');
        const permission = _.get(payload.payload, 'tokens.permission');
        const role = _.get(payload.payload, 'tokens.permission');
        const first_name = _.get(payload.payload, 'tokens.first_name');
        const last_name = _.get(payload.payload, 'tokens.last_name');
        const middle_name = _.get(payload.payload, 'tokens.middle_name');
        const email = _.get(payload.payload, 'tokens.email');
        if (access) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                access: access,
                refresh: refresh,
                user: user,
                userId: userId,
                image: image,
                company: company,
                role: role,
                user_facilities: user_facilities,
                is_companyadmin: is_companyadmin,
                permission: permission,
                first_name,
                last_name,
                middle_name,
                email
            });
        } else {
            yield put({type: actions.LOGIN_ERROR});
        }
    });
}

export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
        yield localStorage.setItem('access', payload.access);
        yield localStorage.setItem('refresh', payload.refresh);
        yield localStorage.setItem('user', payload.user);
        yield localStorage.setItem('image', payload.image);
        yield localStorage.setItem('company', payload.company);
        yield localStorage.setItem('user_facilities', payload.user_facilities);
        yield localStorage.setItem('is_companyadmin', payload.is_companyadmin);

    });
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* (payload) {
        yield localStorage.removeItem('access');
        yield localStorage.removeItem('refresh');
        yield localStorage.removeItem('user');
        yield localStorage.removeItem('image');
        yield localStorage.removeItem('company');
        yield localStorage.removeItem('user_facilities');
        yield localStorage.removeItem('is_companyadmin');
        console.log('payload', payload.payload);
        // yield call(api.logout, payload.payload, payload.payload.authToken);
        history.push('/');
    });
}

export function* loginError() {
    yield takeEvery(actions.LOGIN_ERROR, function* () {
    });
}

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        // console.log('auth')
        const access = localStorage.getItem('access');
        const refresh = localStorage.getItem('refresh');
        const user = localStorage.getItem('user');
        if (access) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                access: access,
                refresh: refresh,
                user: user,
            });
        } else {
            yield put({type: actions.LOGIN_ERROR});
        }
    });
}

export default function* LoginSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(loginSuccess),
        fork(loginError),
        fork(logout),
    ]);
}
