import React from 'react';
import {Provider} from 'react-redux';
import {persistor, store} from './redux/store';
import AppThemeProvider from "./themeprovider/ThemeProvider";
import Routes from './router';
import 'antd/dist/antd.css';
import GlobalStyles from "./themeprovider/globalStyle";
import {PersistGate} from 'redux-persist/lib/integration/react';
import Boot from './redux/boot';

const App = () => (
    <Provider store={store}>
        <AppThemeProvider>
            <>
                <PersistGate persistor={persistor}>
                    <GlobalStyles/>
                    <Routes/>
                </PersistGate>
            </>
        </AppThemeProvider>
    </Provider>
);
Boot()
    .then(() => App())
    .catch(error => console.error(error));

export default App;
