const actions = {


    SET_PDFDATA: 'SET_PDFDATA',

    setPDFData: (index, data) => ({
        type: actions.SET_PDFDATA,
        payload: {index, data}
    }),


}

export default actions;
