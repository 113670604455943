import actions from './actions';

const initState = {
    data: [],
    center_date: null,
    duration: null,
    SelectedTask: [],
    isOpen: false,
    max_task_date: null,
    min_task_date: null,
};

export default function TaskReducer(state = initState, action) {
    switch (action.type) {
        case actions.Task_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            };
        case actions.CENTER_DATE:
            return {
                ...state,
                center_date: action.payload.date,
                duration: action.payload.duration,
                max_task_date: action.payload.max_task_date,
                min_task_date: action.payload.min_task_date
            };
        case actions.IS_OPEN:
            return {
                ...state,
                isOpen: action.payload.isOpen
            };
        case actions.SELECTED_TASK:
            return {
                ...state,
                SelectedTask: action.payload.task
            };
        default:
            return state;
    }
}
