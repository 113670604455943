import React from 'react';
import {ConfigProvider} from 'antd';
import {ThemeProvider} from 'styled-components';
import themes from './theme';


export default function AppThemeProvider({children}) {
    return (
        <ConfigProvider>
            <ThemeProvider theme={themes}>{children}</ThemeProvider>
        </ConfigProvider>
    )
}
